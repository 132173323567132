import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeKeycloak } from './utils/app-init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MatSidenavModule } from '@angular/material/sidenav';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { MatDialogModule } from '@angular/material/dialog';
import { EditPythonCommandComponentModule } from './components/edit-python-command/edit-python-command.component.module';
import { EditTextCommandComponentModule } from './components/edit-text-command/edit-text-command.component.module';
import { FlowsModule } from './modules/flows/flows.module';
import { AddEditConnectorElementComponentModule } from './components/add-edit-connector-element/add-edit-connector-element.component.module';
import { HomeModule } from './modules/home/home.module';
import { ConnectorsModule } from './modules/connectors/connectors.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './utils/loading.interceptor';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
        LoadingComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        AppRoutingModule,
        KeycloakAngularModule,
        MatSnackBarModule,
        MatSelectModule,
        MatSidenavModule,
        MatListModule,
        NgxGraphModule,
        MatDialogModule,
        EditPythonCommandComponentModule,
        EditTextCommandComponentModule,
        AddEditConnectorElementComponentModule,
        FlowsModule,
        ConnectorsModule,
        HomeModule
    ], providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
