// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-sidenav-container {
  height: 100%;
}

mat-sidenav {
  width: 10rem;
}
@media screen and (min-width: 600px) {
  mat-sidenav {
    width: 12rem;
  }
}
mat-sidenav .entry {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
}

mat-sidenav-content {
  padding-top: 3.5rem;
}
@media screen and (min-width: 600px) {
  mat-sidenav-content {
    padding-top: 4rem;
  }
}

.filters mat-form-field div.mat-form-field-flex {
  align-items: flex-end;
}
.filters mat-form-field div.mat-form-field-prefix {
  padding-right: 12px !important;
}

.mat-drawer-content {
  display: inline;
}

.mat-drawer-container {
  background-color: white;
}

html, body {
  height: 100%;
  margin: 0;
}

.full-height-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

mat-sidenav-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAGA;EACI,YAAA;AAAJ;AACI;EAFJ;IAGM,YAAA;EAEJ;AACF;AAAI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AAER;;AAGA;EACI,mBAAA;AAAJ;AACI;EAFJ;IAGQ,iBAAA;EAEN;AACF;;AAEQ;EACI,qBAAA;AACZ;AAEQ;EACI,8BAAA;AAAZ;;AAKA;EACI,eAAA;AAFJ;;AAKA;EACI,uBAAA;AAFJ;;AAKA;EACI,YAAA;EACA,SAAA;AAFJ;;AAKA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AAFJ;;AAKA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;AAFJ","sourcesContent":["mat-sidenav-container {\n    height:100%;\n}\n\n// Move the content down so that it won't be hidden by the toolbar\nmat-sidenav {\n    width: 10rem;\n    @media screen and (min-width: 600px) {\n      width: 12rem;\n    }\n\n    .entry{\n        display: flex;\n        align-items: center;\n        gap: 1rem;\n        padding:0.75rem;\n    }\n  }\n\n// Move the content down so that it won't be hidden by the toolbar\nmat-sidenav-content{\n    padding-top: 3.5rem;\n    @media screen and (min-width: 600px) {\n        padding-top: 4rem;\n    }\n}\n.filters {\n    mat-form-field {\n        div.mat-form-field-flex {\n            align-items: flex-end;\n        }\n\n        div.mat-form-field-prefix {\n            padding-right: 12px !important;\n        }\n    }\n}\n\n.mat-drawer-content {\n    display: inline;\n}\n\n.mat-drawer-container {\n    background-color: white;\n}\n\nhtml, body {\n    height: 100%;\n    margin: 0;\n}\n\n.full-height-container {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n}\n\nmat-sidenav-container {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
