import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    data: { animationState: 'HomePage' }
  },
  {
    path: 'flows',
    loadChildren: () => import('./modules/flows/flows.module').then(m => m.FlowsModule),
    data: { animationState: 'FlowsPage' }
  },
  {
    path: 'connectors',
    loadChildren: () => import('./modules/connectors/connectors.module').then(m => m.ConnectorsModule),
    data: { animationState: 'ConnectorsPage' }
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/plans/plans.module').then(m => m.PlansModule),
    data: { animationState: 'PlansPage' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
