// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* add-edit-connector.component.css */
.container {
  display: flex;
  width: 100%;
}

.form-container {
  width: 30%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.video-container {
  width: 70%;
  padding: 16px;
  box-sizing: border-box;
}

.video-description {
  margin-top: 16px;
}

.form-field {
  width: 100%;
  margin-bottom: 16px;
}

.form-checkbox {
  margin-bottom: 16px;
}

.custom-form-field {
  width: 100%;
  margin-bottom: 16px;
}

.dialog-content {
  flex: 1 1 auto;
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/app/components/add-edit-connector-element/add-edit-connector-element.component.scss"],"names":[],"mappings":"AAAA,qCAAA;AACA;EACE,aAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,qBAAA;EACA,aAAA;EACA,sBAAA;AACF","sourcesContent":["/* add-edit-connector.component.css */\n.container {\n  display: flex;\n  width: 100%;\n}\n\n.form-container {\n  width: 30%;\n  padding: 16px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n}\n\n.video-container {\n  width: 70%;\n  padding: 16px;\n  box-sizing: border-box;\n}\n\n.video-description {\n  margin-top: 16px;\n}\n\n.form-field {\n  width: 100%;\n  margin-bottom: 16px;\n}\n\n.form-checkbox {\n  margin-bottom: 16px;\n}\n\n.custom-form-field {\n  width: 100%;\n  margin-bottom: 16px;\n}\n\n.dialog-content {\n  flex: 1 1 auto;\n}\n\n.dialog-actions {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  padding: 16px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
