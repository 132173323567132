import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) { }

    show(message: string, action?: string, config?: MatSnackBarConfig, messageType: string = "success"): void {
        const defaultConfig: MatSnackBarConfig = {
            duration: 5000,
            panelClass: ['custom-snackbar-' + messageType],
            horizontalPosition: "center",
            verticalPosition: "top",
        }
        const defaultAction = 'Fechar';
        this.snackBar.open(message, action || defaultAction, config || defaultConfig);
    }

    error(message: string, action?: string, config?: MatSnackBarConfig): void {
        this.show(message, action, config, "error");
    }

    success(message: string, action?: string, config?: MatSnackBarConfig): void {
        this.show(message, action, config);
    }
}
