// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flows-container {
  padding: 20px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.add-button {
  text-align: right;
  margin-bottom: 10px;
}

table {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/connectors/list-connectors/list-connectors.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,iBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,WAAA;AACJ","sourcesContent":[".flows-container {\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .header {\n    margin-bottom: 20px;\n  }\n  \n  .add-button {\n    text-align: right;\n    margin-bottom: 10px;\n  }\n  \n  table {\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
