// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.container {
  padding: 20px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.add-button {
  text-align: right;
  margin-bottom: 10px;
}

table {
  width: 100%;
}

.acoes-rapidas {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: large;
  font-weight: bold;
}

.bullet-char {
  content: "•";
  margin: 15px;
}

.dashboard-container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dashboard-iframe {
  width: 100%;
  height: 100%;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/home/home.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,kBAAA;AAEF;;AACA;EACE,mBAAA;AAEF;;AACA;EACE,iBAAA;EACA,mBAAA;AAEF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AAEF;;AACA;EACE,YAAA;EACA,YAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AAEF","sourcesContent":[".container {\n  padding: 20px;\n  text-align: center;\n}\n\n.header {\n  margin-bottom: 20px;\n}\n\n.add-button {\n  text-align: right;\n  margin-bottom: 10px;\n}\n\ntable {\n  width: 100%;\n}\n\n.acoes-rapidas {\n  text-align: center;\n  margin-top: 50px;\n  margin-bottom: 50px;\n  font-size: large;\n  font-weight: bold;\n}\n\n.bullet-char {\n  content: \"\\2022\";\n  margin: 15px;\n}\n\n.dashboard-container {\n  width: 100%;\n  height: 60vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #f4f4f4;\n  overflow-y: hidden;\n  overflow-x: hidden;\n}\n\n.dashboard-iframe {\n  width: 100%;\n  height: 100%;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
