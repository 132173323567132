// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

    .spinner {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #3498db;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.ts"],"names":[],"mappings":";IACI;MACE,eAAe;MACf,MAAM;MACN,OAAO;MACP,WAAW;MACX,YAAY;MACZ,8BAA8B;MAC9B,aAAa;MACb,uBAAuB;MACvB,mBAAmB;MACnB,aAAa;IACf;;IAEA;MACE,yBAAyB;MACzB,6BAA6B;MAC7B,kBAAkB;MAClB,WAAW;MACX,YAAY;MACZ,kCAAkC;IACpC;;IAEA;MACE,KAAK,uBAAuB,EAAE;MAC9B,OAAO,yBAAyB,EAAE;IACpC","sourcesContent":["\n    .loading-overlay {\n      position: fixed;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      background: rgba(0, 0, 0, 0.5);\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      z-index: 9999;\n    }\n\n    .spinner {\n      border: 8px solid #f3f3f3;\n      border-top: 8px solid #3498db;\n      border-radius: 50%;\n      width: 60px;\n      height: 60px;\n      animation: spin 2s linear infinite;\n    }\n\n    @keyframes spin {\n      0% { transform: rotate(0deg); }\n      100% { transform: rotate(360deg); }\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
