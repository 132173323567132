import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService extends AppService {

  getList(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/connectors`);
  }

  get(org, id): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/connectors/${id}`);
  }

  add(org, connector): Observable<any> {
    return this.http.post<any>(`${this.APP_SVC}/${org}/connectors/`, connector);
  }

  update(org, connector): Observable<any> {
    return this.http.put<any>(`${this.APP_SVC}/${org}/connectors/${connector["_id"]}`, connector);
  }

  delete(org, connector): Observable<any> {
    return this.http.delete<any>(`${this.APP_SVC}/${org}/connectors/${connector["_id"]}`);
  }

  refreshApiKey(org, connector): Observable<any> {
    return this.http.post<any>(`${this.APP_SVC}/${org}/connectors/${connector["_id"]}/refresh-api-key`, {});
  }

}
