import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { FlowService } from 'src/app/shared/services/flow.service';
import { LocalService } from 'src/app/shared/services/local.service';

export interface Flow {
  _id: string;
  name: string;
  type: string;
  active: string;
}

@Component({
  selector: 'app-list-flows',
  templateUrl: './list-flows.component.html',
  styleUrls: ['./list-flows.component.scss']
})
export class ListFlowsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'type', 'connector', 'status', 'actions'];
  flows: Flow[] = [];
  selectedOrg = undefined;

  constructor(
    private router: Router,
    private readonly localService: LocalService,
    private readonly flowService: FlowService,
    private readonly keycloakService: KeycloakService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.keycloakService.loadUserProfile().then(profile => {
      this.selectedOrg = this.localService.getData(profile.id);
      this.flowService.getList(this.selectedOrg).subscribe(r => this.flows = r);
    })
  }

  add(): void {
    this.router.navigate(['/flows/add']);
  }

  edit(flow: Flow): void {
    this.router.navigate(['/flows/edit', flow["_id"]]);
  }

  delete(flow: Flow): void {
    console.log("deletou")
    this.flowService.delete(this.selectedOrg, flow).subscribe(r => this.refresh());
  }

  onToggle(event: any, flow): void {
    flow.status = event.checked;
    this.flowService.update(this.selectedOrg, flow).subscribe(r => this.refresh());
  }
}
