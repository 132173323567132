// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  display: flex;
  justify-content: space-between;
}

.cancel-button {
  margin-right: auto;
}

.confirm-button {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAEE;EACE,iBAAA;AACJ","sourcesContent":[".mat-dialog-actions {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .cancel-button {\n    margin-right: auto;\n  }\n  \n  .confirm-button {\n    margin-left: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
