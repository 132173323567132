import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { LocalService } from 'src/app/shared/services/local.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('dashboardFrame', { static: false }) dashboardFrame!: ElementRef;

  dashboardUrl: SafeResourceUrl = "";
  selectedOrg = undefined;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private readonly localService: LocalService,
    private readonly keycloakService: KeycloakService,
    private readonly dashboardService: DashboardService
  ) { }

  fixFooter() {
    if (this.dashboardFrame) {
      const iframe = this.dashboardFrame.nativeElement;
      iframe.onload = () => {
        if (iframe && iframe.contentWindow) {
          const iframeDocument = iframe.contentWindow.document;
          // const targetElement = iframeDocument.querySelector('.EmbedFrame');
          // console.log('target element', targetElement);
          // if (targetElement) {
          //   targetElement.style.overflow = 'hidden';
          // }
          const footerElement = iframeDocument.querySelector('.EmbedFrame-footer');
          console.log('footer element', footerElement);
          if (footerElement) {
            footerElement.style.display = 'none';
          }
        }
      };
    }
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.keycloakService.loadUserProfile().then(profile => {
      this.selectedOrg = this.localService.getData(profile.id);
      this.dashboardService.getDashboardUrl(this.selectedOrg).subscribe(r => {
        console.log("url", r.url);
        this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(r.url);
        setTimeout(() => {
          this.fixFooter()
        }, 0.5);
      });
    })
  }

  addFlow(): void {
    this.router.navigate(['/flows/add']);
  }

  runTour(): void {
  }

  showDocuments(): void {

  }

}
