import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  protected APP_SVC = '/backend/services/v0.0.1';

  constructor(protected http: HttpClient) { }

}
