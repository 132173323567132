import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ConnectorService } from 'src/app/shared/services/connector.service';
import { LocalService } from 'src/app/shared/services/local.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import * as CryptoJS from 'crypto-js';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-add-edit-connector',
  templateUrl: './add-edit-connector.component.html',
  styleUrls: ['./add-edit-connector.component.scss']
})
export class AddEditConnectorComponent implements OnInit {
  connectorTypes = [
    {
      id: 1,
      name: "Slack",
      read: true,
      write: true,
      video_url: "https://www.youtube.com/watch?v=NaREAzoTW1M",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 2,
      name: "Discord",
      read: true,
      write: true,
      video_url: "https://www.youtube.com/watch?v=fKksxz2Gdnc",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 3,
      name: "E-Mail",
      read: false,
      write: true,
      video_url: "https://www.youtube.com/watch?v=ovZjAF1HJVw",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 4,
      name: "Microsoft Teams",
      read: true,
      write: true,
      video_url: " https://www.youtube.com/watch?v=VDDPoYOQYfM",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 5,
      name: "WebEx",
      read: true,
      write: true,
      video_url: "https://www.youtube.com/watch?v=qqA4iHVNxBU",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 6,
      name: "Dynatrace",
      read: true,
      write: false,
      video_url: "https://www.youtube.com/watch?v=sAVg3xyNe0s",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 7,
      name: "Kubernetes",
      read: true,
      write: false,
      video_url: "https://www.youtube.com/watch?v=PziYflu8cB8",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
    {
      id: 8,
      name: "Webhook",
      read: true,
      write: false,
      video_url: "https://www.youtube.com/watch?v=NaREAzoTW1M",
      video_description: "Este vídeo demonstra a integração de conectores em uma aplicação Angular. Você aprenderá a adicionar, editar e configurar conectores como Slack, Discord, WhatsApp e Chat Interno."
    },
  ];

  connector = {
    type: {
      id: 0,
      name: "",
      read: false,
      write: false,
      video_url: ""
    },
    name: "",
    read: true,
    write: true,
    properties: {
      channel: "",
      token: "",
      apiKey: ""
    }
  };

  isEditMode: boolean = false;
  connectorId: string | null = null;
  selectedOrg;
  applicationUrl = window.location.origin;
  sanitizedVideoUrl: SafeResourceUrl;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private readonly localService: LocalService,
    private readonly connectorService: ConnectorService,
    private readonly keycloakService: KeycloakService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.connectorId = this.route.snapshot.paramMap.get('id');
    this.isEditMode = !!this.connectorId;
    this.keycloakService.loadUserProfile().then(profile => {
      this.selectedOrg = this.localService.getData(profile.id);
      if (this.isEditMode) {
        this.connectorService.get(this.selectedOrg, this.connectorId).subscribe(r => {
          this.connector = r
          this.onSelectType(this.connectorTypes.filter((c) => c.name == r.type.name)[0])
          this.refresh();
        });
      } else {
        this.refresh()
      }
    })
  }

  refresh() {

  }


  cancel(): void {
    this.router.navigate(['/connectors']);
  }

  save(): void {
    const onSuccess = () => {
      this.router.navigate(['/connectors']);
    }
    if (!this.connector.name || this.connector.name.trim() == "") {
      this.snackbarService.error('Informe o nome do conector!');
      return;
    }
    if (this.connector.type.id < 1) {
      this.snackbarService.error('O tipo do conector deve ser informado!');
      return;
    }
    if (this.connector.read && this.connector.type.id != 8 && (!this.connector.properties || !this.connector.properties.channel || this.connector.properties.channel.trim() == "")) {
      // this.snackbarService.error('O canal de leitura deve ser informado!');
      // return;
    }
    if (this.connector.read && this.connector.type.id != 8 && (!this.connector.properties || !this.connector.properties.token || this.connector.properties.token.trim() == "")) {
      // this.snackbarService.error('O token de leitura deve ser informado!');
      // return;
    }
    if (this.connector.read && this.connector.type.id != 8 && (!this.connector.properties || !this.connector.properties.apiKey || this.connector.properties.apiKey.trim() == "")) {
      // this.snackbarService.error('A apiKey para escrita deve ser informada!');
      // return;
    }
    if (!this.connector["_id"]) {
      this.connectorService.add(this.selectedOrg, this.connector).subscribe(onSuccess);
    } else {
      this.connectorService.update(this.selectedOrg, this.connector).subscribe(onSuccess);
    }
  }


  onSelectType(connectorType) {
    if (connectorType) {
      this.connector.type = connectorType;
      this.connector.read = this.connector.type.read;
      this.connector.write = this.connector.type.write;
      this.sanitizedVideoUrl = this.getSanitizedUrl(this.connector.type.video_url);
    }
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    const videoId = this.extractVideoId(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  extractVideoId(url: string): string {
    const videoIdMatch = url.match(/[?&]v=([^&]+)/);
    return videoIdMatch ? videoIdMatch[1] : '';
  }

}
