import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ListFlowsComponent } from './list-flows/list-flows.component';
import { AddEditFlowComponent } from './add-edit-flow/add-edit-flow.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ListFlowsComponent,
    AddEditFlowComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    RouterModule.forChild([
      { path: '', component: ListFlowsComponent, data: { animationState: 'ListFlowsPage' } },
      { path: 'add', component: AddEditFlowComponent, data: { animationState: 'AddFlowPage' } },
      { path: 'edit/:id', component: AddEditFlowComponent, data: { animationState: 'EditFlowPage' } }
    ]),
  ],
  exports: [ListFlowsComponent, AddEditFlowComponent]
})
export class FlowsModule { }
