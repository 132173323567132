import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessorService } from 'src/app/shared/services/processor.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-edit-python-command',
  templateUrl: './edit-python-command.component.html',
  styleUrls: ['./edit-python-command.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPythonCommandComponent implements OnInit {

  selectedOrg;
  command = {
    pattern: "",
    script: "",
    requirements: "",
    sensitiveVariables: "",
    processor: {
      responsePattern: ""
    }
  }
  consoleScript = ""
  message = ""
  themeStyle = 'vs-dark'
  editorOptions = { theme: this.themeStyle, language: 'python' };
  requirementOptions = { theme: this.themeStyle, language: 'plaintext' };
  variablesOptions = { theme: this.themeStyle, language: 'plaintext' };

  constructor(
    public dialogRef: MatDialogRef<EditPythonCommandComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public processorService: ProcessorService,
    public snackbarService: SnackbarService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.command = this.data.nodeElement;
    this.selectedOrg = this.data.selectedOrg;
  }

  onConfirm(): void {
    this.dialogRef.close(this.command);
  }

  onCancel(): void {
    this.dialogRef.close();
  }


  onTest(): void {
    console.log(this.command)
    const testableProcessor = {
      type: "2",
      pattern: this.command.pattern,
      script: this.command.script,
      requirements: this.command.requirements,
      sensitiveVariables: this.command.sensitiveVariables,
      message: this.message
    }
    this.consoleScript = "";
    this.processorService.test(this.selectedOrg, testableProcessor).subscribe(
      r => {
        this.consoleScript = r.response
        this.cdr.detectChanges();
      },
      e => {
        this.consoleScript = e.error.message;
        this.cdr.detectChanges();
      }
    );
  }

}
