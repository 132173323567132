import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class FlowService extends AppService {

  getList(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/flows`);
  }

  get(org, id): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/flows/${id}`);
  }

  add(org, flow): Observable<any> {
    return this.http.post<any>(`${this.APP_SVC}/${org}/flows/`, flow);
  }

  update(org, flow): Observable<any> {
    return this.http.put<any>(`${this.APP_SVC}/${org}/flows/${flow["_id"]}`, flow);
  }

  delete(org, flow): Observable<any> {
    return this.http.delete<any>(`${this.APP_SVC}/${org}/flows/${flow["_id"]}`);
  }


}
