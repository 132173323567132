import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { ConnectorService } from 'src/app/shared/services/connector.service';
import { LocalService } from 'src/app/shared/services/local.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';


@Component({
  selector: 'app-list-connectors',
  templateUrl: './list-connectors.component.html',
  styleUrls: ['./list-connectors.component.scss']
})
export class ListConnectorsComponent implements OnInit {
  connectors: any[] = [];
  displayedColumns: string[] = ['name', 'type', 'read', 'write', 'actions'];
  selectedOrg = undefined;

  constructor(
    private connectorService: ConnectorService,
    private router: Router,
    private readonly localService: LocalService,
    private readonly keycloakService: KeycloakService,
    private snackbarService: SnackbarService,
    private confirmationService: ConfirmationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.keycloakService.loadUserProfile().then(profile => {
      this.selectedOrg = this.localService.getData(profile.id);
      this.connectorService.getList(this.selectedOrg).subscribe(r => this.connectors = r);
    })
  }


  add(): void {
    this.router.navigate(['/connectors/add']);
  }

  clipboardApiKey(connector: any) {
    const index = this.connectors.findIndex(c => c["_id"] === connector["_id"]);
    if (index !== -1) {
      navigator.clipboard.writeText(this.connectors[index].properties.apiKey);
      this.snackbarService.success('API Key copiada para a área de transferência!');
    }

  }

  refreshApiKey(connector: any) {
    this.confirmationService.confirm("Deseja realmente gerar uma nova API Key? A API Key anterior será perdida.", "Sim").subscribe(result => {
      if (result) {
        this.connectorService.refreshApiKey(this.selectedOrg, connector).subscribe((updatedConnector) => {
          const index = this.connectors.findIndex(c => c["_id"] === connector["_id"]);
          if (index !== -1) {
            console.log("last", this.connectors[index], "new", updatedConnector);
            this.connectors[index] = updatedConnector;
          }
          this.snackbarService.success('API Key atualizada com sucesso!');
        });
      }
    });

  }


  edit(connector: any): void {
    this.router.navigate(['/connectors/edit', connector["_id"]]);
  }

  delete(connector: any): void {
    this.confirmationService.confirm().subscribe(result => {
      if (result) {
        this.connectorService.delete(this.selectedOrg, connector).subscribe(() => {
          this.connectors = this.connectors.filter(c => c["_id"] !== connector["_id"]);
          this.snackbarService.success('Conector removido com sucesso!');
        });
      }
    });

  }
}
