import { ChangeDetectionStrategy, Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessorService } from 'src/app/shared/services/processor.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-edit-text-command',
  templateUrl: './edit-text-command.component.html',
  styleUrls: ['./edit-text-command.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTextCommandComponent implements OnInit {

  command;
  selectedOrg;
  message = "";

  constructor(
    public dialogRef: MatDialogRef<EditTextCommandComponent>,
    public processorService: ProcessorService,
    public snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.command = this.data.nodeElement;
    this.selectedOrg = this.data.selectedOrg;
  }

  onConfirm(): void {
    this.dialogRef.close(this.command);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onTest(): void {
    const testableProcessor = {
      type: "1",
      pattern: this.command.pattern,
      responsePattern: this.command.processor.responsePattern,
      message: this.message
    }
    this.processorService.test(this.selectedOrg, testableProcessor).subscribe(r => this.snackbarService.success(r.response), e => this.snackbarService.error(e.error.message));
  }
}
