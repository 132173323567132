import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {
    constructor(private dialog: MatDialog) { }

    confirm(message?: string, actionButton?: string): Observable<boolean> {
        if (!message) {
            message = "Tem certeza de que deseja excluir este item?"
        }
        if (!actionButton) {
            actionButton = "Excluir"
        }

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                message: message,
                actionButton: actionButton
            }
        });
        return dialogRef.afterClosed();
    }
}
