import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as dagreD3 from 'dagre-d3';
import * as d3 from 'd3';
import { MatDialog } from '@angular/material/dialog';
import { EditPythonCommandComponent } from 'src/app/components/edit-python-command/edit-python-command.component';
import { KeycloakService } from 'keycloak-angular';
import { LocalService } from 'src/app/shared/services/local.service';
import { ConnectorService } from 'src/app/shared/services/connector.service';
import { AddEditConnectorElementComponent } from 'src/app/components/add-edit-connector-element/add-edit-connector-element.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowService } from 'src/app/shared/services/flow.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { EditTextCommandComponent } from 'src/app/components/edit-text-command/edit-text-command.component';

interface Connector {
  id: string;
  type: number;
  write: boolean;
  read: boolean;
  properties: any;
}

interface Trigger {
  id: string;
  type: number;
  cron: string;
  connector: Connector;
}

interface Flow {
  id: string;
  name: string;
  status: boolean;
  trigger: Trigger;
  commands: Command[];
  destination: Connector
}

interface ProcessorType {
  id: string;
  name: string;
}

interface Processor {
  id: string;
  type: ProcessorType;
  variables: any[];
  responsePattern: string;
}

interface Command {
  id: string;
  position: number;
  pattern: string;
  type: number;
  editable: boolean;
  processor: Processor;
  destination: Connector;
  script: string;
  requirements: string;
  sensitiveVariables: string;
}

@Component({
  selector: 'app-add-edit-flow',
  templateUrl: './add-edit-flow.component.html',
  styleUrls: ['./add-edit-flow.component.scss'],
})
export class AddEditFlowComponent implements OnInit, AfterViewInit {

  private graph: any;

  private flowId: string;

  private processorTypes: ProcessorType[] = [
    { id: "1", name: "Padrão de Texto" },
    { id: "2", name: "Python" },
    { id: "3", name: "Inteligência Artificial" },
    { id: "4", name: "Conteúdo de Ajuda do Fluxo" },
  ]

  flow: Flow = {
    id: "f1",
    name: "",
    status: false,
    trigger: {
      id: "",
      type: 1,
      cron: "",
      connector: {
        id: "",
        type: 0,
        read: true,
        write: false,
        properties: {}
      },
    },
    destination: {
      id: "",
      type: 0,
      read: true,
      write: false,
      properties: {}
    },
    commands: []
  };

  selectedNodeElement = undefined;
  nodeElement = undefined;
  nodeElementType = undefined;
  positionOptions: number[] = [];
  cronExpression = "0 0 * * *"
  connectors = []
  selectedOrg;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private readonly localService: LocalService,
    private readonly connectorService: ConnectorService,
    private readonly flowService: FlowService,
    private readonly keycloakService: KeycloakService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.flowId = params.get('id');
    });
  }

  refresh() {
    this.loadConnectors();
    this.loadFlowGraph();
  }

  ngAfterViewInit(): void {
    this.keycloakService.loadUserProfile().then(profile => {
      this.selectedOrg = this.localService.getData(profile.id);
      if (this.flowId) {
        this.flowService.get(this.selectedOrg, this.flowId).subscribe(r => {
          this.flow = r
          this.refresh();
        });
      } else {
        this.refresh()
      }
    })

  }

  getCommandId(command): string {
    return command["_id"] || command.id
  }

  getIconByTriggerType(processorType) {
    switch (processorType) {
      case 1:
        return "raio.png"
      case 2:
        return "clock.png"
      default:
        return "icon-processor-notfound.png"
    }
  }

  loadFlowGraph() {
    this.graph = new dagreD3.graphlib.Graph().setGraph({ rankdir: 'LR' }).setDefaultEdgeLabel(() => { return {}; });
    const triggerId = this.flow.trigger.id || "1"
    const triggerType: number = this.flow.trigger.type
    const htmlTrigger = '' +
      '<div style="text-align: center;">' +
      '  <div>' +
      '    <span style="font-weight: bold;">Gatilho</span>' +
      '  </div>' +
      '  <div style="margin-top: 10px;">' +
      (triggerType == 1 ? '<img src="assets/imgs/' + this.getIconByConnectorType(this.flow.trigger.connector) + '" style="width:24px; height:24px;">' : '') +
      '    <img src="assets/imgs/' + this.getIconByTriggerType(triggerType) + '" style="width:24px; height:24px;">' +
      (triggerType == 1 ? '<img src="assets/imgs/' + this.getIconByConnectorType(this.flow.destination) + '" style="width:24px; height:24px;">' : '') +
      '  </div>' +
      '</div>';
    this.graph.setNode(triggerId, { labelType: 'html', label: htmlTrigger, class: 'type-TOP', properties: this.flow.trigger, type: "trigger" });
    this.updatePositionOptions();
    this.flow.commands = this.flow.commands.sort((c1, c2) => c1.position - c2.position)
    this.flow.commands.forEach(command => {
      this.addCommand(triggerId, command);
    });
    if (this.flow.commands.length < 1) {
      this.onSelectNode(1)
    }
    setTimeout(() => this.renderGraph(), 50);
  }

  loadConnectors() {
    this.connectorService.getList(this.selectedOrg).subscribe(r => this.connectors = r);
  }

  addNewCommand(pattern?, editable: boolean = true, processor?: Processor) {
    if (this.flow.commands.length >= 6) {
      console.log("Limite de comandos atingido (6).")
      return;
    }
    let newPosition = this.flow.commands.length + 1;
    let wildcardCommand;
    if (editable) {
      wildcardCommand = this.flow.commands.slice(-1)[0];
      newPosition = newPosition - 1
    }
    if (!processor) {
      processor = {
        id: "",
        type: this.processorTypes[0],
        variables: [],
        responsePattern: ""
      }
    }
    const command = {
      id: new Date().getTime() + "",
      position: newPosition,
      pattern: pattern || "",
      type: 1,
      editable: editable,
      processor: processor,
      destination: this.flow.destination,
      script: "",
      requirements: "",
      sensitiveVariables: ""
    }
    this.flow.commands.push(command)
    if (editable) {
      wildcardCommand.position = this.flow.commands.length
    }
    this.loadFlowGraph();
  }

  deleteSelectedCommand(): void {
    const tempElementById = this.flow.commands.find(c => c.id == this.selectedNodeElement.id)
    if (tempElementById) {
      const indexCommand = this.flow.commands.indexOf(tempElementById);
      if (indexCommand >= 0) {
        this.flow.commands.splice(indexCommand, 1);
        const origem = this.selectedNodeElement.position;
        const destino = this.flow.commands.length + 1;
        this.relocateCommandPositions(origem, destino);
        this.selectedNodeElement = undefined;
        this.nodeElement = undefined;
        this.nodeElementType = undefined;
        this.loadFlowGraph();
      }
    }
  }

  addNewConnector() {
    const dialogWidth = window.innerWidth * 0.8 + 'px';
    const dialogHeight = window.innerHeight * 0.8 + 'px';

    const dialogRef = this.dialog.open(AddEditConnectorElementComponent, {
      width: dialogWidth,
      // height: dialogHeight,
      disableClose: true,
      data: {
        type: 0,
        read: true,
        write: false,
        properties: {}
      }
    });

    dialogRef.afterClosed().subscribe(connector => {
      if (connector) {
        this.connectorService.add(this.selectedOrg, connector).subscribe(r => this.loadConnectors())
      }
    });
  }

  getIconByProcessorType(processorType) {
    switch (processorType) {
      case 1:
        return "icon-processor-simpletext.png"
      case 2:
        return "icon-processor-python.png"
      case 3:
        return "icon-processor-chatgpt.png"
      default:
        return "icon-processor-notfound.png"
    }
  }

  getIconByConnectorType(connector) {
    let connectorTypeName: string = ""
    if (connector) {
      connectorTypeName = connector.type.name
    }
    switch (connectorTypeName) {
      case "Slack":
        return "icon-connector-slack.png"
      case "Discord":
        return "icon-connector-discord.png"
      case "Chat":
        return "icon-connector-chat.png"
      case "E-Mail":
        return "icon-connector-email.png"
      case "Webhook":
        return "icon-connector-webhook.png"
      default:
        return "icon-connector-notfound.png"
    }
  }

  addCommand(triggerId: string, command: Command): void {
    const htmlCommand = '' +
      '<div style="text-align: left;">' +
      '  <div style="display: flex; align-items: center;">' +
      '    <img src="assets/imgs/' + this.getIconByProcessorType(command.type) + '" style="width:24px; height:24px;">' +
      `    <span style="margin-left: 8px;">${command.position}. ${command.pattern}</span>` +
      '  </div>' +
      '</div>';
    const htmlConnector = '' +
      '<div style="text-align: left;">' +
      '  <div style="display: flex; align-items: center;">' +
      '    <img src="assets/imgs/' + this.getIconByConnectorType(command.destination) + '" style="width:24px; height:24px;">' +
      '  </div>' +
      '</div>';
    this.graph.setNode(this.getCommandId(command), { labelType: 'html', label: htmlCommand, properties: command, type: "command" });
    this.graph.setNode(`d-${this.getCommandId(command)}`, { labelType: 'html', label: htmlConnector, properties: command, type: "destination" });
    this.graph.setEdge(triggerId, this.getCommandId(command), {});
    this.graph.setEdge(this.getCommandId(command), `d-${this.getCommandId(command)}`, {});
  }

  renderGraph(): void {
    const render = new dagreD3.render();

    const svg = d3.select('#workflowSvg');
    svg.selectAll('*').remove();
    const svgGroup = svg.append('g');

    render(d3.select('#workflowSvg g'), this.graph);

    const container = document.getElementById('svgContainer');
    const width = container.clientWidth;

    svg.attr('width', width);
    svg.attr('height', this.graph.graph().height + 40);

    const xCenterOffset = (width - this.graph.graph().width) / 2;
    svgGroup.attr('transform', 'translate(' + xCenterOffset + ', 20)');

    svg.selectAll('.edgePath path')
      .style('stroke', '#000')
      .style('stroke-width', '2px');

    svg.selectAll('.node rect')
      .style('fill', '#ffffff')
      .style('stroke', '#000')
      .style('stroke-width', '1px')
      .attr('rx', '15')
      .attr('ry', '15');

    svg.selectAll('.node text').style('fill', '#000');

    // const zoom = d3.zoom().scaleExtent([0.5, 2]).on('zoom', (trigger) => { svgGroup.attr('transform', trigger.transform); });
    // svg.call(zoom);

    svg.selectAll('.node').on('click', (trigger: any, nodeId: string) => {
      trigger.stopPropagation();
      this.onSelectNode(nodeId)
      setTimeout(() => this.renderGraph(), 50);
    });

    svg.selectAll('.node').style('cursor', 'pointer');

  }

  onSelectNode(nodeId): void {
    const node = this.graph.node(nodeId);
    this.nodeElement = node.properties;
    this.nodeElementType = node.type;
    this.selectedNodeElement = JSON.parse(JSON.stringify(this.nodeElement || {}))
    if (this.nodeElementType == "trigger") {
      if (this.flow.trigger.connector) {
        this.flow.trigger.connector = this.getReadConnectors().find(c => c["_id"] == this.flow.trigger.connector["_id"])
      }
      if (this.flow.destination) {
        this.flow.destination = this.getWriteConnectors().find(c => c["_id"] == this.flow.destination["_id"])
      }
    } else if (this.nodeElementType == "command") {
      if (!this.nodeElement.processor) {
        const processor: Processor = {
          id: "",
          type: this.processorTypes[0],
          variables: [],
          responsePattern: ""
        }
        this.nodeElement.processor = processor;
      } else {
        this.nodeElement.processor.type = this.processorTypes.find(p => p.id == this.nodeElement.processor.type.id)
      }
    } else if (this.nodeElementType == "destination") {
      if (this.nodeElement.destination) {
        this.nodeElement.destination = this.getWriteConnectors().find(c => c["_id"] == this.nodeElement.destination["_id"])
      }
    }
  }

  onAcionamentoChange(trigger: any): void {
    this.flow.trigger.type = parseInt(trigger.value)
  }

  cancelEdit() {
    this.selectedNodeElement = undefined;
    this.nodeElement = undefined;
    this.nodeElementType = undefined;
    setTimeout(() => this.renderGraph(), 50);
  }

  relocateCommandPositions(origem, destino) {
    if (origem > destino) {
      this.flow.commands.forEach(command => {
        if (
          command.position >= destino &&
          command.position < origem &&
          this.getCommandId(command) != this.getCommandId(this.selectedNodeElement)
        ) {
          console.log("+", command)
          command.position = command.position + 1
        }
      })
    } else if (origem < destino) {
      this.flow.commands.forEach(command => {
        if (
          command.position > origem &&
          command.position <= destino &&
          this.getCommandId(command) != this.getCommandId(this.selectedNodeElement)
        ) {
          console.log("-", command)
          command.position = command.position - 1
        }
      })
    }
  }

  saveNodeElement() {
    if (this.nodeElementType == "trigger") {
      console.log(this.flow.trigger)
      if (this.flow.trigger.connector.id == "") {
        this.snackbarService.error('O conector deve ser informado');
        return;
      }
      if (this.flow.destination.id == "") {
        this.snackbarService.error('O destino padrão deve ser informado');
        return;
      }
      if (this.flow.commands.length < 1) {
        const processor: Processor = {
          id: "",
          type: this.processorTypes[3],
          variables: [],
          responsePattern: ""
        }
        this.addNewCommand("*", false, processor)
      }
    } else if (this.nodeElementType == "command") {
      const origem = this.selectedNodeElement.position;
      const destino = this.nodeElement.position;
      this.relocateCommandPositions(origem, destino);
      console.log("Comando sendo salvo", this.selectedNodeElement, this.flow.commands[1])
    }
    this.selectedNodeElement = undefined;
    this.nodeElement = undefined;
    this.nodeElementType = undefined;
    this.loadFlowGraph();
  }

  updatePositionOptions() {
    this.positionOptions = this.flow.commands.slice(0, -1).map((c, index) => index + 1);

  }

  editProcessor(): void {
    const dialogWidth = window.innerWidth * 0.8 + 'px';
    const dialogHeight = window.innerHeight * 0.8 + 'px';

    let dialogComponent = undefined;
    switch (this.nodeElement.processor.type.id) {
      case "1":
        dialogComponent = EditTextCommandComponent
        break;
      case "2":
        dialogComponent = EditPythonCommandComponent
        break;
      // case 3:
      //   dialog = EditPythonCommandComponent
      //   break;
      // case 4:
      //   dialog = EditPythonCommandComponent
      //   break;
      default:
        this.snackbarService.error('Tipo de processador inválido!');
        break;
    }
    if (dialogComponent) {
      let dialogRef = this.dialog.open(dialogComponent, {
        width: dialogWidth,
        height: dialogHeight,
        data: {
          nodeElement: this.selectedNodeElement,
          selectedOrg: this.selectedOrg
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const command = this.flow.commands.find(i => i["_id"] === result["_id"]);
          command.pattern = result.pattern;
          command.script = result.script;
          command.requirements = result.requirements;
          command.sensitiveVariables = result.sensitiveVariables;
          command.processor.responsePattern = result.processor.responsePattern;

          // this.nodeElement = result;
          // this.nodeElement.processor.type = this.processorTypes.find(p => p.id == this.nodeElement.processor.type.id)

          // this.flow.commands[commandIndex] = this.nodeElement;
          // this.flow.commands = Object.assign([], this.flow.commands);

        }
        dialogRef = null;
      });
    }
  }

  cancel(): void {
    this.router.navigate(['/flows']);
  }

  save(): void {
    console.log("command2", this.flow.commands[1])
    const onSuccess = () => {
      this.router.navigate(['/flows']);
    }
    if (!this.flow.name || this.flow.name.trim() == "") {
      this.snackbarService.error('Informe o nome do fluxo!');
      return;
    }
    if (!this.flow.commands || this.flow.commands.length < 1) {
      this.snackbarService.error('Pelo menos um comando deve ser adicionado!');
      return;
    } else {
      for (const c of this.flow.commands) {
        if (!c.pattern || c.pattern.trim() == "") {
          this.snackbarService.error('Existem comandos sem mensagem associada!');
          return;
        }
        console.log("destination", c.destination);
        if (!c.destination || !c.destination.type["_id"]) {
          this.snackbarService.error('Existem comandos sem destino associado!');
          return;
        }
        if (!c.processor || (c.processor.type.id != "4" && !c.processor.responsePattern)) {
          c.processor.responsePattern = "[Resposta ainda não configurada]"
          // this.snackbarService.error('Existem comandos sem padrão de resposta associada: ');
          // return;
        }
      }
    }
    if (this.flow.trigger.connector.type < 1) {
      this.snackbarService.error('O conector do gatilho deve ser informado!');
      return;
    }
    if (this.flow.id) {
      this.flowService.add(this.selectedOrg, this.flow).subscribe(onSuccess);
    } else {
      this.flowService.update(this.selectedOrg, this.flow).subscribe(onSuccess);
    }
  }

  getReadConnectors() {
    return this.connectors.filter((c) => c.read)
  }

  getWriteConnectors() {
    return this.connectors.filter((c) => c.write)
  }

}