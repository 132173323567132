// add-edit-connector.component.ts
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-connector-element',
  templateUrl: './add-edit-connector-element.component.html',
  styleUrls: ['./add-edit-connector-element.component.scss']
})
export class AddEditConnectorElementComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddEditConnectorElementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data?.name || '', Validators.required],
      type: [this.data?.type || '', Validators.required],
      read: [this.data?.read || false],
      write: [this.data?.write || false],
      properties: this.fb.group({
        channel: [{ value: this.data?.properties?.channel || '', disabled: !this.data?.read }, Validators.required],
        token: [{ value: this.data?.properties?.token || '', disabled: !this.data?.read }, Validators.required],
        apiKey: [{ value: this.data?.properties?.apiKey || '', disabled: !this.data?.write }, Validators.required]
      })
    });

    // Subscribe to changes in the 'type' field
    this.form.get('type').valueChanges.subscribe(value => {
      this.onSelectType(value);
    });

    // Subscribe to changes in the 'read' and 'write' fields to enable/disable properties controls
    this.form.get('read').valueChanges.subscribe(() => {
      this.togglePropertiesControls();
    });
    this.form.get('write').valueChanges.subscribe(() => {
      this.togglePropertiesControls();
    });

    // Initialize properties controls state based on 'read' and 'write' values
    this.togglePropertiesControls();
  }

  onSelectType(type: number): void {
    // Logic to handle changes based on the selected type
    console.log('Selected type:', type);
    // Here you can set additional form controls or perform other actions
  }

  togglePropertiesControls(): void {
    const read = this.form.get('read').value;
    const write = this.form.get('write').value;
    const properties = this.form.get('properties') as FormGroup;

    if (read) {
      properties.get('channel').enable();
      properties.get('token').enable();
    } else {
      properties.get('channel').disable();
      properties.get('token').disable();
    }

    if (write) {
      properties.get('apiKey').enable();
    } else {
      properties.get('apiKey').disable();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  onSubmit(): void {
    this.save();
  }
}
