import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends AppService {
  getDashboardUrl(org): Observable<any> {
    return this.http.get<any>(`${this.APP_SVC}/${org}/dashboard`);
  }

}
