import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ListConnectorsComponent } from './list-connectors/list-connectors.component';
import { AddEditConnectorComponent } from './add-edit-connector/add-edit-connector.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    ListConnectorsComponent,
    AddEditConnectorComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    RouterModule.forChild([
      { path: '', component: ListConnectorsComponent, data: { animationState: 'ListConnectorsPage' } },
      { path: 'add', component: AddEditConnectorComponent, data: { animationState: 'AddConnectorPage' } },
      { path: 'edit/:id', component: AddEditConnectorComponent, data: { animationState: 'EditConnectorPage' } }
    ]),
  ],
  exports: [ListConnectorsComponent, AddEditConnectorComponent]
})
export class ConnectorsModule { }
