import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalService } from './local.service';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessorService extends AppService {

  test(org, processor): Observable<any> {
    return this.http.post<any>(`${this.APP_SVC}/${org}/processors/test`, processor);
  }

}
